import { http } from '@/http/axios.js'

// 物流详情
export function getDeliveryDetailAPI(orderNo) {
  return http({
    url: '/capital/order/delivery/trace',
    method: 'get',
    params: { orderNo }
  })
}
